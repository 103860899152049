import * as React from 'react';
import useTheme from '@lobox/uikit/utils/useTheme';

function SvgExperience(props: any) {
  const { isDark } = useTheme();
  return isDark ? (
    <svg
      width={210}
      height={125}
      viewBox="0 0 210 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.692 125S1.226 106.767 2.013 74.294c.787-32.472 32.354-37.552 53.952-17.53 21.685 20.02 32.788 10.226 43.98-6.708 11.193-16.933 28.422-55.249 59.813-40.905 23.435 10.658 39.087 39.245 44.158 65.044 3.498 17.73-1.574 36.456-13.728 48.11-.437.399-2.122 2.396-2.559 2.695H35.692z"
        fill="url(#AME_R_SVG_GJDI)"
      />
      <path
        d="M184.438 17.985v.809s-.199 2.553 2.615 2.917c2.815.381 5.132.318 5.047-1.649-.256-1.522-.711-3.885-.725-5.154 0-.476.028-.967.099-1.522l-5.459-.127-1.606.285.029 4.44z"
        fill="#FFE5C2"
      />
      <path
        d="M184.423 13.56l.014 4.409a4.231 4.231 0 001.962.492c2.502 0 4.136-2.046 4.975-3.537 0-.476.028-.967.1-1.522l-5.459-.127-1.592.285z"
        fill="#FED2A4"
      />
      <path
        d="M191.899 12.418c-.639 3.806-5.032 3.68-6.936 3.442a8.039 8.039 0 01-.868-.143c-.597-.174-.938-1.237-1.094-2.633-.313-2.743.085-6.771.497-7.818.626-1.554 1.806-3.235 6.241-2.014 4.421 1.205 2.971 4.282 2.16 9.166z"
        fill="#FFE5C2"
      />
      <path
        d="M195.496 124.983h-6.226c.042-.365.199-1.475.497-2.49.1-.349.213-.698.355-.999.612-1.348 4.45-.491 4.45-.491s.355.348.639 1.649c.128.571.228 1.332.285 2.331zM176.661 122.795c-.014.952-.128 1.84-.426 2.188H163.91c.157-.491.597-1.252 1.862-1.474 1.436-.254 3.682-1.031 4.99-2.569.469-.556.81-1.19.966-1.951.583-2.886 4.791.888 4.791.888s.171 1.507.142 2.918z"
        fill="#112B46"
      />
      <path
        d="M184.863 91.648c-.171 1.062-.285 1.919-.313 2.537-.398 8.532-6.439 28.022-7.52 28.482-1.677.698-4.307-.793-5.686-1.284a1.329 1.329 0 01-.824-1.491c2.985-15.351 4.094-37.442 4.492-50.526.199-6.597 1.222-11.148 1.222-11.148s14.741-4.393 14.827 1.157c.071 4.916-4.876 24.185-6.198 32.273z"
        fill="url(#SvgExperience1_linear)"
      />
      <path
        d="M184.863 91.648l-9.837-22.282c.199-6.597 1.223-11.148 1.223-11.148s14.741-4.393 14.826 1.157c.057 4.916-4.89 24.185-6.212 32.273z"
        fill="#233862"
      />
      <path
        d="M181.593 59.408l7.79 62.991s3.425.92 6.084.19c0 0 2.274-33.493 2.089-41.597-.185-8.104 1.635-21.679-.412-23.17-2.061-1.506-15.551 1.586-15.551 1.586z"
        fill="url(#SvgExperience2_linear)"
      />
      <path
        d="M182.134 1.65s1.948-.174 4.251-1.284c1.862-.904 4.222.095 4.989.54a1.252 1.252 0 00-.185-.508s.342.27.398.65c.029.032.057.048.043.064 0 0 .384-.08.64.047 0 0-.171-.032-.242.095 0 .016-.014.032-.028.032 2.914 1.57 3.184 6.708 2.246 7.882-1.123 1.427-2.474 4.646-2.474 4.646s-.497-.76-.284-2.014c.199-1.253-.768-1.253-2.004-1.823-1.223-.571-.199-2.173-1.28-3.14-1.08-.968-5.018-.397-4.975-1.142-1.464-.603-1.095-3.87-1.095-4.044z"
        fill="url(#SvgExperience3_linear)"
      />
      <path
        d="M192.766 21.172l-1.791 42.93s-14.969.27-14.542-3.505c.426-3.774 5.402-38.108 5.402-38.108l10.931-1.317z"
        fill="#fff"
      />
      <path
        d="M198.112 45.5c-.895 2.98.782 12.987 1.394 16.365.127.714.213 1.142.213 1.142-2.076 3.759-9.666 3.711-9.666 3.711-1.465-7.2-2.218-18.666-2.218-18.666l5.828-28.482 7.335 7.565s-.554 4.615-1.237 9.293c-.554 3.711-1.165 7.501-1.649 9.071z"
        fill="url(#SvgExperience4_linear)"
      />
      <path
        d="M185.66 13.037s-.142.491-1.251.317c0 0-.938-.143-.867-.697 0 0 .938-.317 2.118.38z"
        fill="#fff"
      />
      <path
        d="M177.244 21.776v.016c-.355.38-10.348 11.212-12.282 15.446-1.976 4.314 9.013 12.877 9.013 12.877s.71.317 1.194.19c-.512 4.758-1.095 9.151-.754 11.022.612 3.378 3.426 4.52 3.426 4.25 0-.079 1.024-12.306 1.578-18.681 1.194-13.67 4.194-27.245 4.194-27.245-3.27.38-6.369 2.125-6.369 2.125zm-5.942 17.333c-.014-.015-.014-.047-.014-.063 0-.618 2.118-1.982 4.407-3.6l.042-.047a42.37 42.37 0 00.029 3.806c.128 2.236-.014 4.9-.256 7.612-1.08-1.903-3.994-7.01-4.208-7.708z"
        fill="url(#SvgExperience5_linear)"
      />
      <path
        d="M193.193 19.523c.014-.412-1.72-.983-1.72-.983s-1.99 3.077-5.274 3.14c0 0 .938 2.03 2.033 2.553.014 0 4.862-2.283 4.961-4.71zM186.2 21.6s-1.735-1.142-1.749-2.395c0 0-1.123-.159-1.606.444 0 0-.412 2.14.128 3.378.014.016 2.516-.413 3.227-1.428z"
        fill="#D9E9F9"
      />
      <path
        d="M187.379 21.425l-2.359-.634s-.313.032-.299.46c.014.428.668 1.68.953 1.776.298.095 2.274-.745 1.705-1.602z"
        fill="url(#SvgExperience6_linear)"
      />
      <path
        d="M192.923 19.301c.014-.412-1.166-1.253-1.166-1.253s-2.274 2.966-5.558 3.03c0 0 .938 2.03 2.033 2.553.014 0 4.577-1.888 4.691-4.33zM186.199 21.078s-1.763-1.047-1.763-2.284c0 0-1.066.524-1.549 1.126 0 0-.455 1.348.099 2.585 0 0 2.502-.412 3.213-1.427z"
        fill="#fff"
      />
      <path
        d="M181.594 50.701c.085 1 .497 1.935 1.151 2.617l1.038 1.078c.256.27.668.238.896-.08l1.293-1.839a2.63 2.63 0 00.455-1.728c-.128-1.38-.341-4.06-.526-6.63-.142-2.093-.27-4.122-.27-5.344-.028-3.948.668-16.508.668-16.508l-.853.047s-2.104 4.885-3.198 11.514a48.344 48.344 0 00-.583 5.17c.014-.016-.426 7.168-.071 11.703z"
        fill="url(#SvgExperience7_linear)"
      />
      <path
        d="M181.594 50.701c.085 1 .497 1.935 1.151 2.617l1.038 1.078c.256.27.668.238.896-.079l1.293-1.84c.341-.491.512-1.11.455-1.728-.128-1.38-.341-4.06-.526-6.63-2.345-2.933-3.284-6.993-3.653-10.291a48.345 48.345 0 00-.583 5.17c.014-.016-.426 7.168-.071 11.703z"
        fill="url(#SvgExperience8_linear)"
      />
      <path
        d="M173.263 50.813c0-1.094.867-1.967 1.833-1.808.271.048.512.174.683.412.668 1 1.308 3.87.113 4.013-.483.047-1.094-.318-1.577-.73-.484-.412-1.052-1.047-1.052-1.729v-.158z"
        fill="#FFE5C2"
      />
      <path
        d="M199.505 61.882c-2.146-2.49-5.856-7.39-5.728-11.419.17-5.63 5.97-14.019 5.97-14.019-.54 3.711-1.151 7.486-1.635 9.072-.881 2.965.796 12.972 1.393 16.366z"
        fill="#284875"
        fillOpacity={0.3}
      />
      <path
        d="M199.433 52.065s-1.549.856-2.473.428c-.938-.428-2.033-.762-3.085.57-1.052 1.333-1.265 2.252-1.052 2.744.228.492 2.659.301 3.071 0 .398-.301 1.052-1.062 1.45-.936 1.123.365 2.061-.586 2.587-1.348.327-.444-.54-.666-.498-1.459z"
        fill="#FFE5C2"
      />
      <path
        d="M193.661 19.57s6.141.761 7.591 3.743c1.45 2.981 8.984 15.082 8.742 20.109-.241 5.027-10.277 10.672-10.277 10.672s-2.218-1.046-2.26-2.172c-.029-1.126 6.937-7.866 6.51-9.119-.426-1.253-8.103-11.196-8.842-14.479-.739-3.283-1.464-8.754-1.464-8.754z"
        fill="url(#SvgExperience9_linear)"
      />
      <path
        d="M175.737 35.383l-.043.047c-2.288 1.618-4.407 2.981-4.407 3.6-.312-1.094 2.673-5.646 3.64-6.581.966-.936.81 2.934.81 2.934z"
        fill="#3E6297"
        fillOpacity={0.3}
      />
      <path
        d="M191.757 18.048s-4.663 21.806-3.924 30.005c0 0 1.72-8.77 5.843-15.97 0 0-1.507-1.475-1.422-2.03 0 0 1.123-.364 2.204-.127 0 0-.114-9.404-.796-10.387-.669-.967-1.905-1.49-1.905-1.49z"
        fill="url(#SvgExperience10_linear)"
      />
      <path
        d="M184.436 18.795c.099-.064-4.62 21.298-5.018 28.117 0 0 .469-12.148.426-15.653 0 0 1.934-1.141 1.934-1.538 0-.396-2.062-1.49-2.062-1.49s1.308-7.486 2.019-8.31c.711-.825 2.701-1.126 2.701-1.126z"
        fill="url(#SvgExperience11_linear)"
      />
      <path
        d="M66.077 77.988c-.232-6.592-.76-12.33-1.364-16.34-.977-6.384-1.861-10.046-1.877-10.08-.124-.559-2-2.826-6.14-2.756-4.156.07-3.753 3.052-3.753 3.052 0 .104.016.191.031.261.047.157 3.846 15.678 3.69 35.734 0 .279-.03.767-.015 1.099 0 0 9.614.279 9.614 0 .326-1.762.155-6.627-.186-10.97z"
        fill="url(#SvgExperience12_linear)"
      />
      <path
        d="M67.287 87.754c0 .28-.14.506-.294.506-.155 0-.295-.227-.295-.506h-8.234c0 .28-.14.506-.295.506-.155 0-.294-.227-.294-.523a159.616 159.616 0 00-.683-15.835c-1.1-11.807-3.116-19.69-3.148-19.794a1.18 1.18 0 01-.03-.262s-.388-2.982 3.752-3.052c4.156-.07 6.017 2.197 6.14 2.756 0 .034.512 2.11 1.18 5.842.216 1.238.465 2.65.697 4.238.62 3.993 1.132 9.73 1.365 16.34.356 4.36.48 8.04.14 9.784z"
        fill="url(#SvgExperience13_linear)"
      />
      <path
        d="M67.287 87.755c0 .279-.14.506-.294.506-.155 0-.295-.227-.295-.506h-8.234c0 .279-.14.506-.295.506-.155 0-.294-.227-.294-.523.046-5.738-.248-11.11-.683-15.836 1.365-7.481 6.622-13.219 7.893-14.51.217 1.239.466 2.651.698 4.238.62 3.994 1.132 9.732 1.365 16.341.356 4.36.48 8.04.14 9.784z"
        fill="url(#SvgExperience14_linear)"
      />
      <path
        d="M58.975 87.667l.016-.017s.015-.018.015-.053c0-.087.016-.14.063-.191.263-.297 1.255-.297 2.527-.28 1.38.018 3.101.035 4.807-.296.884-.174.295-7.83-.341-14.998-.109-1.22-.186-2.18-.233-2.755-.403-6.07-2.775-16.917-2.79-17.004-.11-.453-1.536-2.145-4.622-2.093-1.163.018-1.985.349-2.45.96-.48.627-.387 1.342-.387 1.36 0 .07 0 .14.03.209.016.052 2.14 14.056 2.497 17.422.574 5.529.806 17.004.806 17.632V87.615l.062.052zm1.846-.645c-.915 0-1.566.052-1.8.314 0-.262-.015-.75-.046-1.692-.03-1.099-.077-2.808-.14-4.743-.123-3.384-.325-8.023-.635-10.987-.357-3.436-2.481-17.388-2.497-17.44-.015-.052-.015-.122-.015-.174 0-.018-.078-.698.372-1.29.45-.594 1.24-.908 2.373-.925h.14c2.93 0 4.28 1.587 4.388 2.005.03.105 2.388 10.883 2.79 16.987h.047-.046c.03.575.124 1.534.232 2.755.42 4.779 1.288 14.72.42 14.894-1.707.33-3.412.313-4.792.296h-.791z"
        fill="url(#SvgExperience15_linear)"
      />
      <path
        d="M57.254 88.26H89.57s-.76-3.418-19.46-2.913c-13.243.367-12.856 2.913-12.856 2.913z"
        fill="url(#SvgExperience16_linear)"
      />
      <path
        d="M54.633 124.03c3.737-21.329 2.776-34.409 2.776-34.583-.016-.332.527-.628.822-.645.294 0 1.581-.105 1.736.191.202.367.946 16.498-4.636 35.037h-.698z"
        fill="url(#SvgExperience17_linear)"
      />
      <path
        d="M84.795 124.03c-3.737-21.329-2.776-34.409-2.776-34.583.016-.332-.527-.628-.822-.645-.294 0-1.581-.105-1.736.191-.202.367-.946 16.498 4.636 35.037h.698z"
        fill="url(#SvgExperience18_linear)"
      />
      <path
        d="M90.78 124.03c-3.753-21.329-2.792-34.409-2.776-34.583.015-.332-.528-.628-.822-.645-.295 0-1.582-.105-1.737.191-.202.367-.946 16.498 4.637 35.037h.697z"
        fill="url(#SvgExperience19_linear)"
      />
      <path
        d="M60.65 124.03c3.753-21.329 2.776-34.409 2.776-34.583-.015-.332.528-.628.822-.645.295 0 1.582-.105 1.737.191.202.367.946 16.498-4.636 35.037h-.698z"
        fill="url(#SvgExperience20_linear)"
      />
      <path
        d="M89.586 89.36h-32.3c-.357 0-.667-.332-.667-.75v-.489c0-.4.295-.75.667-.75h32.3c.357 0 .667.332.667.75v.489c0 .4-.295.75-.667.75z"
        fill="url(#SvgExperience21_linear)"
      />
      <path
        d="M78.684 111.088c-.264.349-.31.732-.17 1.151.247.767 1.054 1.622 2 2.476.124.105.232.21.325.297.031.017.047.052.078.07.015.017.03.035.062.052.884.907.543 1.116 2.124 2.982 1.799 2.11 3.055 2.145 3.396 1.953.295-.157-.31-1.29-.977-2.546-.03-.052-.062-.122-.093-.192-.093-.174-.186-.331-.263-.505-.791-1.518-.59-2.233-.403-4.482.154-1.832 2.527-10.237 2.527-10.237l-3.458-.663s-1.039 6.697-2.186 7.726c-.62.54-1.303.558-1.892.959-.047.035-.078.052-.124.087-.326.279-.667.523-.946.872z"
        fill="#FFE5C2"
      />
      <path
        d="M78.684 111.088c-.263.349-.31.733-.17 1.151.294.767 1.1 1.622 2 2.459.124.105.217.209.326.314.03.017.046.052.077.07.016.017.031.035.062.052.884.907.543 1.116 2.125 2.982 1.798 2.11 3.055 2.145 3.396 1.953.294-.157-.31-1.29-.977-2.546-.357-.139-1.985-.889-3.21-2.668-1.086-1.552-1.814-3.558-2.559-4.726-.046.035-.077.052-.124.087-.325.279-.667.523-.946.872z"
        fill="#112B46"
      />
      <path
        d="M106.581 108.664c.217 1.204 1.737.995 3.442.541 1.706-.471 1.303.087 3.722-.209 2.419-.297 3.148-1.308 3.225-1.727.062-.366-1.132-.488-2.419-.645-.155-.017-.325-.035-.48-.07-1.443-.209-2.512-1.255-3.831-2.11-1.24-.82-3.101-4.151-3.101-4.151l-3.318.297s1.736 2.982 2.419 4.273c.403.767.372 1.761.325 2.546-.046.401-.062.837.016 1.255z"
        fill="#FFE5C2"
      />
      <path
        d="M110.009 109.206c1.705-.471 1.302.087 3.721-.209 2.419-.297 3.148-1.308 3.226-1.727.062-.366-1.132-.488-2.419-.645l-.016.035s-1.457 1.151-3.458 1.203c-1.644.053-3.38-.488-4.512-.471-.031.419-.047.855.031 1.273.201 1.204 1.736.994 3.427.541z"
        fill="#112B46"
      />
      <path
        d="M99.184 53.851s.79-2.18 1.659-2.267c.868-.087 3.644-.575 3.83-.14.202.437 0 2.041-.589 2.617-.589.575-4.264 1.517-4.264 1.517l-.636-1.727z"
        fill="#FFE5C2"
      />
      <path
        d="M93.865 84.807l-7.148 21.643s-1.101.122-2.156-.227c-.977-.314-2.078-1.133-2.078-1.133s1.768-20.788 5.024-25.114c3.241-4.325 6.358 4.831 6.358 4.831z"
        fill="url(#SvgExperience22_linear)"
      />
      <path
        d="M65.582 76.052s20.546-7.813 25.136-6.156c4.575 1.656 18.329 32.455 18.329 32.455s-.31.471-1.163.819c-.791.332-2.698.314-2.698.314S92.765 91.521 91.292 88.015c-1.473-3.487-2.063-6.819-3.551-6.487-1.473.331-19.073 8.144-23.059 3.488-3.985-4.657-2.946-10.708-2.946-10.708l3.846 1.744z"
        fill="url(#SvgExperience23_linear)"
      />
      <path
        d="M78.126 39.691c.326-.767.698-2.11.868-4.534-.03.244-.403 3.278-1.442 5.005-4.28 3.156-12.467 13.69-12.297 15.696 0 0-2.884-5.511-2.93-5.529 0 0-5.32-7.604.992-11.964 3.21-2.214 3.194-5.057 3.055-7.533-.14-2.477.868-13.098 7.304-10.325 0 0 1.023-.523 1.488-.436 0 0-.372.088-.573.297.744-.052 1.814.017 2.698.575 2.357 1.5 4.466 14.998.837 18.748z"
        fill="url(#SvgExperience24_linear)"
      />
      <path
        d="M77.32 40.319c.016.017-2.062 1.22-3.628.75-1.473-.454-2.435-2.599-2.42-2.616.62-.14.807-1.099.791-5.25l.28.07 4.745 1.064s-.109 1.674-.109 3.261c0 1.326.062 2.581.341 2.721z"
        fill="#FFE5C2"
      />
      <path
        d="M77.086 34.336s-.109 1.675-.109 3.261c-2.651-.087-4.062-2.807-4.636-4.325l4.745 1.064z"
        fill="#FED2A4"
      />
      <path
        d="M71.846 32.697s-1.396-2.477-1.722-4.587c-.232-1.517.45-7.115 5.443-6.296 0 0 1.458.157 2.822 1.204 1.086.82 2.652 2.598.9 7.499l-.45 1.656-6.993.524z"
        fill="url(#SvgExperience25_linear)"
      />
      <path
        d="M77.97 35.837s-6.28.122-6.295-4.29c0-4.413-.822-7.36 3.194-7.621 4.016-.262 4.792 1.412 5.117 2.895.326 1.465-.372 8.859-2.015 9.016z"
        fill="#FFE5C2"
      />
      <path
        d="M77.738 23.82s-1.721 5.162-4.357 5.93c-2.636.767-3.784-.035-3.784-.035s2.016-1.814 2.435-4.97c0 0 4.404-3.384 5.706-.925z"
        fill="url(#SvgExperience26_linear)"
      />
      <path
        d="M77.32 24.24s1.287 1.151 1.597 2.267c.31 1.116.45 3.436 1.054 3.349 0 0 1.303-3.506-.155-5.494-1.55-2.11-2.496-.122-2.496-.122z"
        fill="url(#SvgExperience27_linear)"
      />
      <path
        d="M60.758 76.174c.341 1.064 1.38 1.325 2.776 1.151 2.651-.349 6.605-2.354 9.443-3.75 4.342-2.11 6.311-.121 7.692-1.464 1.38-1.343-1.582-4.343.992-18.225.868-4.709-4.636-14.806-4.636-14.806s-.481 2.93-5.738-.593c-.186-.122-2.264 3.331-4.264 7.586-2.202 4.691-4.311 10.377-3.69 13.29 1.162 5.563-3.567 13.724-2.575 16.811z"
        fill="url(#SvgExperience28_linear)"
      />
      <path
        d="M77.009 39.062l3.256 14.98s.403.803 1.04 1.954c1.488 2.703 4.233 7.307 6 7.76.869.227 2.714-.575 4.76-1.743 3.862-2.198 8.436-5.65 8.436-5.65.295-1.989-1.24-3.628-1.24-3.628s-9.257 3.924-10.188 3.993c-2 .157-4.047-10.725-5.923-14.352-1.35-2.599-6.141-3.314-6.141-3.314z"
        fill="url(#SvgExperience29_linear)"
      />
      <path
        d="M76.576 33.168s.124.558 1.349.47c0 0 1.039-.069 1.008-.679 0-.017-1.008-.453-2.357.21z"
        fill="#fff"
      />
      <path
        d="M60.758 76.175c.341 1.064 1.38 1.326 2.776 1.151 1.845-.907 9.785-4.36 9.692-6.714-.14-3.47-1.535-3.575-.543-7.865.79-3.436-6.544-9.836-7.722-11.231-2.202 4.69-2.264 4.918-1.644 7.847 1.179 5.564-3.551 13.725-2.559 16.812z"
        fill="#3E6297"
        fillOpacity={0.3}
      />
      <path
        d="M81.29 55.996c1.488 2.703 4.232 7.307 6 7.76.869.227 2.714-.575 4.76-1.743-1.488.436-2.945.418-4.357.261-1.535-.174-1.969-2.267-4.388-5.423-1.55-2.04-1.938-1.518-2.016-.855z"
        fill="#4A75CB"
      />
      <path
        d="M73.94 62.658s1.334-1.168 2.59-.89c1.256.28 1.876 1.256 1.83 1.692-.047.436-.713 1.692-1.675 1.797-.961.104-3.69-.663-3.69-.663l.945-1.936z"
        fill="#FFE5C2"
      />
      <path
        d="M71.273 38.47s-5.118.349-6.9 3.139c-2.497 3.924-6.84 19.898-5.645 23.56l15.584-.086s.372-1.989-.155-2.948c-.527-.959-9.072-2.511-9.46-2.947-.387-.436 4.932-8.65 4.932-9.976 0-1.325 1.644-10.742 1.644-10.742z"
        fill="url(#SvgExperience30_linear)"
      />
      <path
        d="M75.273 69.198h18.871l3.412-13.341H78.7l-3.427 13.341z"
        fill="url(#SvgExperience31_linear)"
      />
      <path d="M69.41 68.675h24.672v.523H69.411v-.523z" fill="#163560" />
      <path
        d="M88.158 62.31c.124-.47-.124-.854-.542-.854-.419 0-.853.383-.977.854s.124.855.543.855c.418 0 .852-.384.976-.855z"
        fill="#fff"
      />
      <path
        d="M16.841 71.628l-1.346 8.466-3.686 23.428-3.3 20.923c-.049.322-.305.555-.594.555-.256 0-.464-.251-.432-.537l2.852-23.733 3.045-25.38.465-3.83 2.996.108z"
        fill="url(#SvgExperience32_linear)"
      />
      <path
        d="M31.488 71.628l-1.346 8.466-3.702 23.41-3.3 20.923c-.049.322-.305.555-.594.555-.256 0-.464-.25-.432-.537l2.852-23.715 3.045-25.38.464-3.83 3.013.108z"
        fill="url(#SvgExperience33_linear)"
      />
      <path
        d="M15.512 80.112c.176-3.633-2.131-4.743-2.131-4.743l.465-3.83 3.012.09-1.346 8.483z"
        fill="url(#SvgExperience34_linear)"
      />
      <path
        d="M143.132 124.982c-.288 0-.545-.232-.593-.555l-2.932-18.596-4.231-26.722-1.186-7.48 3.013-.09.529 4.349 3.67 30.498 2.179 18.077c.016.269-.192.519-.449.519z"
        fill="url(#SvgExperience35_linear)"
      />
      <path
        d="M137.732 75.87c-1.955.966-2.356 3.24-2.356 3.24l-1.186-7.482 3.013-.09.529 4.332z"
        fill="url(#SvgExperience36_linear)"
      />
      <path
        d="M160.006 124.982c-.288 0-.545-.232-.593-.555l-2.932-18.596-4.231-26.722-1.186-7.48 3.013-.09.529 4.349 3.67 30.498 2.179 18.077c.016.269-.192.519-.449.519z"
        fill="url(#SvgExperience37_linear)"
      />
      <path
        d="M154.59 75.87c-1.955.966-2.355 3.24-2.355 3.24l-1.186-7.482 3.013-.09.528 4.332z"
        fill="url(#SvgExperience38_linear)"
      />
      <path
        d="M159.606 74.564H2.596C1.17 74.564 0 73.257 0 71.663v-.054c0-1.61 1.17-2.917 2.596-2.917h157.01c1.426 0 2.596 1.306 2.596 2.9v.053c0 1.61-1.17 2.918-2.596 2.918z"
        fill="url(#SvgExperience39_linear)"
      />
      <defs>
        <linearGradient
          id="SvgExperience0_linear"
          x1={103.5}
          y1={6}
          x2={103.5}
          y2={125}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#363636" />
          <stop offset={1} stopColor="#242424" />
        </linearGradient>
        <linearGradient
          id="SvgExperience1_linear"
          x1={170.509}
          y1={89.74}
          x2={191.079}
          y2={89.74}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience2_linear"
          x1={181.604}
          y1={90.171}
          x2={198.14}
          y2={90.171}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience3_linear"
          x1={188.431}
          y1={0.034}
          x2={188.431}
          y2={13.754}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience4_linear"
          x1={187.22}
          y1={26.76}
          x2={212.496}
          y2={53.946}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience5_linear"
          x1={163.842}
          y1={26.66}
          x2={186.154}
          y2={61.98}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience6_linear"
          x1={184.723}
          y1={21.914}
          x2={187.484}
          y2={21.914}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience7_linear"
          x1={181.459}
          y1={38.438}
          x2={186.442}
          y2={38.438}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience8_linear"
          x1={181.459}
          y1={44.213}
          x2={186.442}
          y2={44.213}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience9_linear"
          x1={192.897}
          y1={24.835}
          x2={208.547}
          y2={53.367}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience10_linear"
          x1={187.441}
          y1={22.623}
          x2={203.389}
          y2={36.351}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B76D7" />
          <stop offset={0.433} stopColor="#3361C4" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience11_linear"
          x1={179.183}
          y1={23.082}
          x2={193.297}
          y2={32.788}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B76D7" />
          <stop offset={0.433} stopColor="#3361C4" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience12_linear"
          x1={59.685}
          y1={48.81}
          x2={59.685}
          y2={89.082}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#88A9E4" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience13_linear"
          x1={60.746}
          y1={48.792}
          x2={60.746}
          y2={88.26}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience14_linear"
          x1={62.34}
          y1={57.393}
          x2={62.34}
          y2={88.261}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience15_linear"
          x1={61.212}
          y1={49.979}
          x2={61.212}
          y2={87.667}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience16_linear"
          x1={73.412}
          y1={85.296}
          x2={73.412}
          y2={88.26}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience17_linear"
          x1={57.374}
          y1={88.784}
          x2={57.374}
          y2={124.03}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A90D6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience18_linear"
          x1={82.054}
          y1={88.784}
          x2={82.054}
          y2={124.03}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A90D6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience19_linear"
          x1={88.039}
          y1={88.784}
          x2={88.039}
          y2={124.03}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A90D6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience20_linear"
          x1={63.391}
          y1={88.784}
          x2={63.391}
          y2={124.03}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A90D6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience21_linear"
          x1={73.436}
          y1={87.372}
          x2={73.436}
          y2={89.36}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DADE7" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience22_linear"
          x1={93.857}
          y1={92.656}
          x2={82.474}
          y2={92.656}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience23_linear"
          x1={109.014}
          y1={86.591}
          x2={61.574}
          y2={86.591}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience24_linear"
          x1={70.123}
          y1={20.137}
          x2={70.123}
          y2={55.702}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience25_linear"
          x1={75.065}
          y1={21.76}
          x2={75.065}
          y2={32.649}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience26_linear"
          x1={73.655}
          y1={22.942}
          x2={73.655}
          y2={30.051}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience27_linear"
          x1={78.908}
          y1={23.407}
          x2={78.908}
          y2={29.83}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience28_linear"
          x1={82.742}
          y1={44.414}
          x2={66.48}
          y2={78.457}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience29_linear"
          x1={101.639}
          y1={42.833}
          x2={94.887}
          y2={67.581}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={0.806} stopColor="#406DC9" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience30_linear"
          x1={75.183}
          y1={42.541}
          x2={64.676}
          y2={66.67}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience31_linear"
          x1={86.414}
          y1={55.857}
          x2={86.414}
          y2={69.198}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience32_linear"
          x1={12.16}
          y1={71.52}
          x2={12.16}
          y2={125}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience33_linear"
          x1={26.799}
          y1={71.52}
          x2={26.799}
          y2={124.982}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience34_linear"
          x1={15.12}
          y1={71.539}
          x2={15.12}
          y2={80.112}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience35_linear"
          x1={138.886}
          y1={71.539}
          x2={138.886}
          y2={124.982}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience36_linear"
          x1={135.961}
          y1={71.539}
          x2={135.961}
          y2={79.109}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience37_linear"
          x1={155.76}
          y1={71.539}
          x2={155.76}
          y2={124.982}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience38_linear"
          x1={152.82}
          y1={71.539}
          x2={152.82}
          y2={79.109}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience39_linear"
          x1={81.101}
          y1={68.693}
          x2={81.101}
          y2={74.564}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={210}
      height={125}
      viewBox="0 0 210 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.692 125S1.226 106.767 2.013 74.294c.787-32.472 32.354-37.552 53.952-17.53 21.685 20.02 32.788 10.226 43.98-6.708 11.193-16.933 28.422-55.249 59.813-40.905 23.435 10.658 39.087 39.245 44.158 65.044 3.498 17.73-1.574 36.456-13.728 48.11-.437.399-2.122 2.396-2.559 2.695H35.692z"
        fill="url(#SvgExperience0_linear)"
      />
      <path
        d="M184.438 17.985v.809s-.199 2.553 2.615 2.917c2.815.381 5.132.318 5.047-1.649-.256-1.522-.711-3.885-.725-5.154 0-.476.028-.967.099-1.522l-5.459-.127-1.606.285.029 4.44z"
        fill="#FFE5C2"
      />
      <path
        d="M184.423 13.56l.014 4.409a4.231 4.231 0 001.962.492c2.502 0 4.136-2.046 4.975-3.537 0-.476.028-.967.1-1.522l-5.459-.127-1.592.285z"
        fill="#FED2A4"
      />
      <path
        d="M191.899 12.418c-.639 3.806-5.032 3.68-6.936 3.442a8.039 8.039 0 01-.868-.143c-.597-.174-.938-1.237-1.094-2.633-.313-2.743.085-6.771.497-7.818.626-1.554 1.806-3.235 6.241-2.014 4.421 1.205 2.971 4.282 2.16 9.166z"
        fill="#FFE5C2"
      />
      <path
        d="M195.496 124.983h-6.226c.042-.365.199-1.475.497-2.49.1-.349.213-.698.355-.999.612-1.348 4.45-.491 4.45-.491s.355.348.639 1.649c.128.571.228 1.332.285 2.331zM176.661 122.795c-.014.952-.128 1.84-.426 2.188H163.91c.157-.491.597-1.252 1.862-1.474 1.436-.254 3.682-1.031 4.99-2.569.469-.556.81-1.19.966-1.951.583-2.886 4.791.888 4.791.888s.171 1.507.142 2.918z"
        fill="#112B46"
      />
      <path
        d="M184.863 91.648c-.171 1.062-.285 1.919-.313 2.537-.398 8.532-6.439 28.022-7.52 28.482-1.677.698-4.307-.793-5.686-1.284a1.329 1.329 0 01-.824-1.491c2.985-15.351 4.094-37.442 4.492-50.526.199-6.597 1.222-11.148 1.222-11.148s14.741-4.393 14.827 1.157c.071 4.916-4.876 24.185-6.198 32.273z"
        fill="url(#SvgExperience1_linear)"
      />
      <path
        d="M184.863 91.648l-9.837-22.282c.199-6.597 1.223-11.148 1.223-11.148s14.741-4.393 14.826 1.157c.057 4.916-4.89 24.185-6.212 32.273z"
        fill="#233862"
      />
      <path
        d="M181.593 59.408l7.79 62.991s3.425.92 6.084.19c0 0 2.274-33.493 2.089-41.597-.185-8.104 1.635-21.679-.412-23.17-2.061-1.506-15.551 1.586-15.551 1.586z"
        fill="url(#SvgExperience2_linear)"
      />
      <path
        d="M182.134 1.65s1.948-.174 4.251-1.284c1.862-.904 4.222.095 4.989.54a1.252 1.252 0 00-.185-.508s.342.27.398.65c.029.032.057.048.043.064 0 0 .384-.08.64.047 0 0-.171-.032-.242.095 0 .016-.014.032-.028.032 2.914 1.57 3.184 6.708 2.246 7.882-1.123 1.427-2.474 4.646-2.474 4.646s-.497-.76-.284-2.014c.199-1.253-.768-1.253-2.004-1.823-1.223-.571-.199-2.173-1.28-3.14-1.08-.968-5.018-.397-4.975-1.142-1.464-.603-1.095-3.87-1.095-4.044z"
        fill="url(#SvgExperience3_linear)"
      />
      <path
        d="M192.766 21.172l-1.791 42.93s-14.969.27-14.542-3.505c.426-3.774 5.402-38.108 5.402-38.108l10.931-1.317z"
        fill="#fff"
      />
      <path
        d="M198.112 45.5c-.895 2.98.782 12.987 1.394 16.365.127.714.213 1.142.213 1.142-2.076 3.759-9.666 3.711-9.666 3.711-1.465-7.2-2.218-18.666-2.218-18.666l5.828-28.482 7.335 7.565s-.554 4.615-1.237 9.293c-.554 3.711-1.165 7.501-1.649 9.071z"
        fill="url(#SvgExperience4_linear)"
      />
      <path
        d="M185.66 13.037s-.142.491-1.251.317c0 0-.938-.143-.867-.697 0 0 .938-.317 2.118.38z"
        fill="#fff"
      />
      <path
        d="M177.244 21.776v.016c-.355.38-10.348 11.212-12.282 15.446-1.976 4.314 9.013 12.877 9.013 12.877s.71.317 1.194.19c-.512 4.758-1.095 9.151-.754 11.022.612 3.378 3.426 4.52 3.426 4.25 0-.079 1.024-12.306 1.578-18.681 1.194-13.67 4.194-27.245 4.194-27.245-3.27.38-6.369 2.125-6.369 2.125zm-5.942 17.333c-.014-.015-.014-.047-.014-.063 0-.618 2.118-1.982 4.407-3.6l.042-.047a42.37 42.37 0 00.029 3.806c.128 2.236-.014 4.9-.256 7.612-1.08-1.903-3.994-7.01-4.208-7.708z"
        fill="url(#SvgExperience5_linear)"
      />
      <path
        d="M193.193 19.523c.014-.412-1.72-.983-1.72-.983s-1.99 3.077-5.274 3.14c0 0 .938 2.03 2.033 2.553.014 0 4.862-2.283 4.961-4.71zM186.2 21.6s-1.735-1.142-1.749-2.395c0 0-1.123-.159-1.606.444 0 0-.412 2.14.128 3.378.014.016 2.516-.413 3.227-1.428z"
        fill="#D9E9F9"
      />
      <path
        d="M187.379 21.425l-2.359-.634s-.313.032-.299.46c.014.428.668 1.68.953 1.776.298.095 2.274-.745 1.705-1.602z"
        fill="url(#SvgExperience6_linear)"
      />
      <path
        d="M192.923 19.301c.014-.412-1.166-1.253-1.166-1.253s-2.274 2.966-5.558 3.03c0 0 .938 2.03 2.033 2.553.014 0 4.577-1.888 4.691-4.33zM186.199 21.078s-1.763-1.047-1.763-2.284c0 0-1.066.524-1.549 1.126 0 0-.455 1.348.099 2.585 0 0 2.502-.412 3.213-1.427z"
        fill="#fff"
      />
      <path
        d="M181.594 50.701c.085 1 .497 1.935 1.151 2.617l1.038 1.078c.256.27.668.238.896-.08l1.293-1.839a2.63 2.63 0 00.455-1.728c-.128-1.38-.341-4.06-.526-6.63-.142-2.093-.27-4.122-.27-5.344-.028-3.948.668-16.508.668-16.508l-.853.047s-2.104 4.885-3.198 11.514a48.344 48.344 0 00-.583 5.17c.014-.016-.426 7.168-.071 11.703z"
        fill="url(#SvgExperience7_linear)"
      />
      <path
        d="M181.594 50.701c.085 1 .497 1.935 1.151 2.617l1.038 1.078c.256.27.668.238.896-.079l1.293-1.84c.341-.491.512-1.11.455-1.728-.128-1.38-.341-4.06-.526-6.63-2.345-2.933-3.284-6.993-3.653-10.291a48.345 48.345 0 00-.583 5.17c.014-.016-.426 7.168-.071 11.703z"
        fill="url(#SvgExperience8_linear)"
      />
      <path
        d="M173.263 50.813c0-1.094.867-1.967 1.833-1.808.271.048.512.174.683.412.668 1 1.308 3.87.113 4.013-.483.047-1.094-.318-1.577-.73-.484-.412-1.052-1.047-1.052-1.729v-.158z"
        fill="#FFE5C2"
      />
      <path
        d="M199.505 61.882c-2.146-2.49-5.856-7.39-5.728-11.419.17-5.63 5.97-14.019 5.97-14.019-.54 3.711-1.151 7.486-1.635 9.072-.881 2.965.796 12.972 1.393 16.366z"
        fill="#284875"
        fillOpacity={0.3}
      />
      <path
        d="M199.433 52.065s-1.549.856-2.473.428c-.938-.428-2.033-.762-3.085.57-1.052 1.333-1.265 2.252-1.052 2.744.228.492 2.659.301 3.071 0 .398-.301 1.052-1.062 1.45-.936 1.123.365 2.061-.586 2.587-1.348.327-.444-.54-.666-.498-1.459z"
        fill="#FFE5C2"
      />
      <path
        d="M193.661 19.57s6.141.761 7.591 3.743c1.45 2.981 8.984 15.082 8.742 20.109-.241 5.027-10.277 10.672-10.277 10.672s-2.218-1.046-2.26-2.172c-.029-1.126 6.937-7.866 6.51-9.119-.426-1.253-8.103-11.196-8.842-14.479-.739-3.283-1.464-8.754-1.464-8.754z"
        fill="url(#SvgExperience9_linear)"
      />
      <path
        d="M175.737 35.383l-.043.047c-2.288 1.618-4.407 2.981-4.407 3.6-.312-1.094 2.673-5.646 3.64-6.581.966-.936.81 2.934.81 2.934z"
        fill="#3E6297"
        fillOpacity={0.3}
      />
      <path
        d="M191.757 18.048s-4.663 21.806-3.924 30.005c0 0 1.72-8.77 5.843-15.97 0 0-1.507-1.475-1.422-2.03 0 0 1.123-.364 2.204-.127 0 0-.114-9.404-.796-10.387-.669-.967-1.905-1.49-1.905-1.49z"
        fill="url(#SvgExperience10_linear)"
      />
      <path
        d="M184.436 18.795c.099-.064-4.62 21.298-5.018 28.117 0 0 .469-12.148.426-15.653 0 0 1.934-1.141 1.934-1.538 0-.396-2.062-1.49-2.062-1.49s1.308-7.486 2.019-8.31c.711-.825 2.701-1.126 2.701-1.126z"
        fill="url(#SvgExperience11_linear)"
      />
      <path
        d="M66.077 77.988c-.232-6.592-.76-12.33-1.364-16.34-.977-6.384-1.861-10.046-1.877-10.08-.124-.559-2-2.826-6.14-2.756-4.156.07-3.753 3.052-3.753 3.052 0 .104.016.191.031.261.047.157 3.846 15.678 3.69 35.734 0 .279-.03.767-.015 1.099 0 0 9.614.279 9.614 0 .326-1.762.155-6.627-.186-10.97z"
        fill="url(#SvgExperience12_linear)"
      />
      <path
        d="M67.287 87.754c0 .28-.14.506-.294.506-.155 0-.295-.227-.295-.506h-8.234c0 .28-.14.506-.295.506-.155 0-.294-.227-.294-.523a159.616 159.616 0 00-.683-15.835c-1.1-11.807-3.116-19.69-3.148-19.794a1.18 1.18 0 01-.03-.262s-.388-2.982 3.752-3.052c4.156-.07 6.017 2.197 6.14 2.756 0 .034.512 2.11 1.18 5.842.216 1.238.465 2.65.697 4.238.62 3.993 1.132 9.73 1.365 16.34.356 4.36.48 8.04.14 9.784z"
        fill="url(#SvgExperience13_linear)"
      />
      <path
        d="M67.287 87.755c0 .279-.14.506-.294.506-.155 0-.295-.227-.295-.506h-8.234c0 .279-.14.506-.295.506-.155 0-.294-.227-.294-.523.046-5.738-.248-11.11-.683-15.836 1.365-7.481 6.622-13.219 7.893-14.51.217 1.239.466 2.651.698 4.238.62 3.994 1.132 9.732 1.365 16.341.356 4.36.48 8.04.14 9.784z"
        fill="url(#SvgExperience14_linear)"
      />
      <path
        d="M58.975 87.667l.016-.017s.015-.018.015-.053c0-.087.016-.14.063-.191.263-.297 1.255-.297 2.527-.28 1.38.018 3.101.035 4.807-.296.884-.174.295-7.83-.341-14.998-.109-1.22-.186-2.18-.233-2.755-.403-6.07-2.775-16.917-2.79-17.004-.11-.453-1.536-2.145-4.622-2.093-1.163.018-1.985.349-2.45.96-.48.627-.387 1.342-.387 1.36 0 .07 0 .14.03.209.016.052 2.14 14.056 2.497 17.422.574 5.529.806 17.004.806 17.632V87.615l.062.052zm1.846-.645c-.915 0-1.566.052-1.8.314 0-.262-.015-.75-.046-1.692-.03-1.099-.077-2.808-.14-4.743-.123-3.384-.325-8.023-.635-10.987-.357-3.436-2.481-17.388-2.497-17.44-.015-.052-.015-.122-.015-.174 0-.018-.078-.698.372-1.29.45-.594 1.24-.908 2.373-.925h.14c2.93 0 4.28 1.587 4.388 2.005.03.105 2.388 10.883 2.79 16.987h.047-.046c.03.575.124 1.534.232 2.755.42 4.779 1.288 14.72.42 14.894-1.707.33-3.412.313-4.792.296h-.791z"
        fill="url(#SvgExperience15_linear)"
      />
      <path
        d="M57.254 88.26H89.57s-.76-3.418-19.46-2.913c-13.243.367-12.856 2.913-12.856 2.913z"
        fill="url(#SvgExperience16_linear)"
      />
      <path
        d="M54.633 124.03c3.737-21.329 2.776-34.409 2.776-34.583-.016-.332.527-.628.822-.645.294 0 1.581-.105 1.736.191.202.367.946 16.498-4.636 35.037h-.698z"
        fill="url(#SvgExperience17_linear)"
      />
      <path
        d="M84.795 124.03c-3.737-21.329-2.776-34.409-2.776-34.583.016-.332-.527-.628-.822-.645-.294 0-1.581-.105-1.736.191-.202.367-.946 16.498 4.636 35.037h.698z"
        fill="url(#SvgExperience18_linear)"
      />
      <path
        d="M90.78 124.03c-3.753-21.329-2.792-34.409-2.776-34.583.015-.332-.528-.628-.822-.645-.295 0-1.582-.105-1.737.191-.202.367-.946 16.498 4.637 35.037h.697z"
        fill="url(#SvgExperience19_linear)"
      />
      <path
        d="M60.65 124.03c3.753-21.329 2.776-34.409 2.776-34.583-.015-.332.528-.628.822-.645.295 0 1.582-.105 1.737.191.202.367.946 16.498-4.636 35.037h-.698z"
        fill="url(#SvgExperience20_linear)"
      />
      <path
        d="M89.586 89.36h-32.3c-.357 0-.667-.332-.667-.75v-.489c0-.4.295-.75.667-.75h32.3c.357 0 .667.332.667.75v.489c0 .4-.295.75-.667.75z"
        fill="url(#SvgExperience21_linear)"
      />
      <path
        d="M78.684 111.088c-.264.349-.31.732-.17 1.151.247.767 1.054 1.622 2 2.476.124.105.232.21.325.297.031.017.047.052.078.07.015.017.03.035.062.052.884.907.543 1.116 2.124 2.982 1.799 2.11 3.055 2.145 3.396 1.953.295-.157-.31-1.29-.977-2.546-.03-.052-.062-.122-.093-.192-.093-.174-.186-.331-.263-.505-.791-1.518-.59-2.233-.403-4.482.154-1.832 2.527-10.237 2.527-10.237l-3.458-.663s-1.039 6.697-2.186 7.726c-.62.54-1.303.558-1.892.959-.047.035-.078.052-.124.087-.326.279-.667.523-.946.872z"
        fill="#FFE5C2"
      />
      <path
        d="M78.684 111.088c-.263.349-.31.733-.17 1.151.294.767 1.1 1.622 2 2.459.124.105.217.209.326.314.03.017.046.052.077.07.016.017.031.035.062.052.884.907.543 1.116 2.125 2.982 1.798 2.11 3.055 2.145 3.396 1.953.294-.157-.31-1.29-.977-2.546-.357-.139-1.985-.889-3.21-2.668-1.086-1.552-1.814-3.558-2.559-4.726-.046.035-.077.052-.124.087-.325.279-.667.523-.946.872z"
        fill="#112B46"
      />
      <path
        d="M106.581 108.664c.217 1.204 1.737.995 3.442.541 1.706-.471 1.303.087 3.722-.209 2.419-.297 3.148-1.308 3.225-1.727.062-.366-1.132-.488-2.419-.645-.155-.017-.325-.035-.48-.07-1.443-.209-2.512-1.255-3.831-2.11-1.24-.82-3.101-4.151-3.101-4.151l-3.318.297s1.736 2.982 2.419 4.273c.403.767.372 1.761.325 2.546-.046.401-.062.837.016 1.255z"
        fill="#FFE5C2"
      />
      <path
        d="M110.009 109.206c1.705-.471 1.302.087 3.721-.209 2.419-.297 3.148-1.308 3.226-1.727.062-.366-1.132-.488-2.419-.645l-.016.035s-1.457 1.151-3.458 1.203c-1.644.053-3.38-.488-4.512-.471-.031.419-.047.855.031 1.273.201 1.204 1.736.994 3.427.541z"
        fill="#112B46"
      />
      <path
        d="M99.184 53.851s.79-2.18 1.659-2.267c.868-.087 3.644-.575 3.83-.14.202.437 0 2.041-.589 2.617-.589.575-4.264 1.517-4.264 1.517l-.636-1.727z"
        fill="#FFE5C2"
      />
      <path
        d="M93.865 84.807l-7.148 21.643s-1.101.122-2.156-.227c-.977-.314-2.078-1.133-2.078-1.133s1.768-20.788 5.024-25.114c3.241-4.325 6.358 4.831 6.358 4.831z"
        fill="url(#SvgExperience22_linear)"
      />
      <path
        d="M65.582 76.052s20.546-7.813 25.136-6.156c4.575 1.656 18.329 32.455 18.329 32.455s-.31.471-1.163.819c-.791.332-2.698.314-2.698.314S92.765 91.521 91.292 88.015c-1.473-3.487-2.063-6.819-3.551-6.487-1.473.331-19.073 8.144-23.059 3.488-3.985-4.657-2.946-10.708-2.946-10.708l3.846 1.744z"
        fill="url(#SvgExperience23_linear)"
      />
      <path
        d="M78.126 39.691c.326-.767.698-2.11.868-4.534-.03.244-.403 3.278-1.442 5.005-4.28 3.156-12.467 13.69-12.297 15.696 0 0-2.884-5.511-2.93-5.529 0 0-5.32-7.604.992-11.964 3.21-2.214 3.194-5.057 3.055-7.533-.14-2.477.868-13.098 7.304-10.325 0 0 1.023-.523 1.488-.436 0 0-.372.088-.573.297.744-.052 1.814.017 2.698.575 2.357 1.5 4.466 14.998.837 18.748z"
        fill="url(#SvgExperience24_linear)"
      />
      <path
        d="M77.32 40.319c.016.017-2.062 1.22-3.628.75-1.473-.454-2.435-2.599-2.42-2.616.62-.14.807-1.099.791-5.25l.28.07 4.745 1.064s-.109 1.674-.109 3.261c0 1.326.062 2.581.341 2.721z"
        fill="#FFE5C2"
      />
      <path
        d="M77.086 34.336s-.109 1.675-.109 3.261c-2.651-.087-4.062-2.807-4.636-4.325l4.745 1.064z"
        fill="#FED2A4"
      />
      <path
        d="M71.846 32.697s-1.396-2.477-1.722-4.587c-.232-1.517.45-7.115 5.443-6.296 0 0 1.458.157 2.822 1.204 1.086.82 2.652 2.598.9 7.499l-.45 1.656-6.993.524z"
        fill="url(#SvgExperience25_linear)"
      />
      <path
        d="M77.97 35.837s-6.28.122-6.295-4.29c0-4.413-.822-7.36 3.194-7.621 4.016-.262 4.792 1.412 5.117 2.895.326 1.465-.372 8.859-2.015 9.016z"
        fill="#FFE5C2"
      />
      <path
        d="M77.738 23.82s-1.721 5.162-4.357 5.93c-2.636.767-3.784-.035-3.784-.035s2.016-1.814 2.435-4.97c0 0 4.404-3.384 5.706-.925z"
        fill="url(#SvgExperience26_linear)"
      />
      <path
        d="M77.32 24.24s1.287 1.151 1.597 2.267c.31 1.116.45 3.436 1.054 3.349 0 0 1.303-3.506-.155-5.494-1.55-2.11-2.496-.122-2.496-.122z"
        fill="url(#SvgExperience27_linear)"
      />
      <path
        d="M60.758 76.174c.341 1.064 1.38 1.325 2.776 1.151 2.651-.349 6.605-2.354 9.443-3.75 4.342-2.11 6.311-.121 7.692-1.464 1.38-1.343-1.582-4.343.992-18.225.868-4.709-4.636-14.806-4.636-14.806s-.481 2.93-5.738-.593c-.186-.122-2.264 3.331-4.264 7.586-2.202 4.691-4.311 10.377-3.69 13.29 1.162 5.563-3.567 13.724-2.575 16.811z"
        fill="url(#SvgExperience28_linear)"
      />
      <path
        d="M77.009 39.062l3.256 14.98s.403.803 1.04 1.954c1.488 2.703 4.233 7.307 6 7.76.869.227 2.714-.575 4.76-1.743 3.862-2.198 8.436-5.65 8.436-5.65.295-1.989-1.24-3.628-1.24-3.628s-9.257 3.924-10.188 3.993c-2 .157-4.047-10.725-5.923-14.352-1.35-2.599-6.141-3.314-6.141-3.314z"
        fill="url(#SvgExperience29_linear)"
      />
      <path
        d="M76.576 33.168s.124.558 1.349.47c0 0 1.039-.069 1.008-.679 0-.017-1.008-.453-2.357.21z"
        fill="#fff"
      />
      <path
        d="M60.758 76.175c.341 1.064 1.38 1.326 2.776 1.151 1.845-.907 9.785-4.36 9.692-6.714-.14-3.47-1.535-3.575-.543-7.865.79-3.436-6.544-9.836-7.722-11.231-2.202 4.69-2.264 4.918-1.644 7.847 1.179 5.564-3.551 13.725-2.559 16.812z"
        fill="#3E6297"
        fillOpacity={0.3}
      />
      <path
        d="M81.29 55.996c1.488 2.703 4.232 7.307 6 7.76.869.227 2.714-.575 4.76-1.743-1.488.436-2.945.418-4.357.261-1.535-.174-1.969-2.267-4.388-5.423-1.55-2.04-1.938-1.518-2.016-.855z"
        fill="#4A75CB"
      />
      <path
        d="M73.94 62.658s1.334-1.168 2.59-.89c1.256.28 1.876 1.256 1.83 1.692-.047.436-.713 1.692-1.675 1.797-.961.104-3.69-.663-3.69-.663l.945-1.936z"
        fill="#FFE5C2"
      />
      <path
        d="M71.273 38.47s-5.118.349-6.9 3.139c-2.497 3.924-6.84 19.898-5.645 23.56l15.584-.086s.372-1.989-.155-2.948c-.527-.959-9.072-2.511-9.46-2.947-.387-.436 4.932-8.65 4.932-9.976 0-1.325 1.644-10.742 1.644-10.742z"
        fill="url(#SvgExperience30_linear)"
      />
      <path
        d="M75.273 69.198h18.871l3.412-13.341H78.7l-3.427 13.341z"
        fill="url(#SvgExperience31_linear)"
      />
      <path d="M69.41 68.675h24.672v.523H69.411v-.523z" fill="#163560" />
      <path
        d="M88.158 62.31c.124-.47-.124-.854-.542-.854-.419 0-.853.383-.977.854s.124.855.543.855c.418 0 .852-.384.976-.855z"
        fill="#fff"
      />
      <path
        d="M16.841 71.628l-1.346 8.466-3.686 23.428-3.3 20.923c-.049.322-.305.555-.594.555-.256 0-.464-.251-.432-.537l2.852-23.733 3.045-25.38.465-3.83 2.996.108z"
        fill="url(#SvgExperience32_linear)"
      />
      <path
        d="M31.488 71.628l-1.346 8.466-3.702 23.41-3.3 20.923c-.049.322-.305.555-.594.555-.256 0-.464-.25-.432-.537l2.852-23.715 3.045-25.38.464-3.83 3.013.108z"
        fill="url(#SvgExperience33_linear)"
      />
      <path
        d="M15.512 80.112c.176-3.633-2.131-4.743-2.131-4.743l.465-3.83 3.012.09-1.346 8.483z"
        fill="url(#SvgExperience34_linear)"
      />
      <path
        d="M143.132 124.982c-.288 0-.545-.232-.593-.555l-2.932-18.596-4.231-26.722-1.186-7.48 3.013-.09.529 4.349 3.67 30.498 2.179 18.077c.016.269-.192.519-.449.519z"
        fill="url(#SvgExperience35_linear)"
      />
      <path
        d="M137.732 75.87c-1.955.966-2.356 3.24-2.356 3.24l-1.186-7.482 3.013-.09.529 4.332z"
        fill="url(#SvgExperience36_linear)"
      />
      <path
        d="M160.006 124.982c-.288 0-.545-.232-.593-.555l-2.932-18.596-4.231-26.722-1.186-7.48 3.013-.09.529 4.349 3.67 30.498 2.179 18.077c.016.269-.192.519-.449.519z"
        fill="url(#SvgExperience37_linear)"
      />
      <path
        d="M154.59 75.87c-1.955.966-2.355 3.24-2.355 3.24l-1.186-7.482 3.013-.09.528 4.332z"
        fill="url(#SvgExperience38_linear)"
      />
      <path
        d="M159.606 74.564H2.596C1.17 74.564 0 73.257 0 71.663v-.054c0-1.61 1.17-2.917 2.596-2.917h157.01c1.426 0 2.596 1.306 2.596 2.9v.053c0 1.61-1.17 2.918-2.596 2.918z"
        fill="url(#SvgExperience39_linear)"
      />
      <defs>
        <linearGradient
          id="SvgExperience0_linear"
          x1={103.5}
          y1={6.228}
          x2={103.5}
          y2={123.401}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DCE9FC" />
          <stop offset={1} stopColor="#DCE9FC" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="SvgExperience1_linear"
          x1={170.509}
          y1={89.74}
          x2={191.079}
          y2={89.74}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience2_linear"
          x1={181.604}
          y1={90.171}
          x2={198.14}
          y2={90.171}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience3_linear"
          x1={188.431}
          y1={0.034}
          x2={188.431}
          y2={13.754}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience4_linear"
          x1={187.22}
          y1={26.76}
          x2={212.496}
          y2={53.946}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience5_linear"
          x1={163.842}
          y1={26.66}
          x2={186.154}
          y2={61.98}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience6_linear"
          x1={184.723}
          y1={21.914}
          x2={187.484}
          y2={21.914}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience7_linear"
          x1={181.459}
          y1={38.438}
          x2={186.442}
          y2={38.438}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience8_linear"
          x1={181.459}
          y1={44.213}
          x2={186.442}
          y2={44.213}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience9_linear"
          x1={192.897}
          y1={24.835}
          x2={208.547}
          y2={53.367}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F8AED" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience10_linear"
          x1={187.441}
          y1={22.623}
          x2={203.389}
          y2={36.351}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B76D7" />
          <stop offset={0.433} stopColor="#3361C4" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience11_linear"
          x1={179.183}
          y1={23.082}
          x2={193.297}
          y2={32.788}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3B76D7" />
          <stop offset={0.433} stopColor="#3361C4" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience12_linear"
          x1={59.685}
          y1={48.81}
          x2={59.685}
          y2={89.082}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#88A9E4" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience13_linear"
          x1={60.746}
          y1={48.792}
          x2={60.746}
          y2={88.26}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience14_linear"
          x1={62.34}
          y1={57.393}
          x2={62.34}
          y2={88.261}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience15_linear"
          x1={61.212}
          y1={49.979}
          x2={61.212}
          y2={87.667}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience16_linear"
          x1={73.412}
          y1={85.296}
          x2={73.412}
          y2={88.26}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BBD3FF" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience17_linear"
          x1={57.374}
          y1={88.784}
          x2={57.374}
          y2={124.03}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A90D6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience18_linear"
          x1={82.054}
          y1={88.784}
          x2={82.054}
          y2={124.03}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A90D6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience19_linear"
          x1={88.039}
          y1={88.784}
          x2={88.039}
          y2={124.03}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A90D6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience20_linear"
          x1={63.391}
          y1={88.784}
          x2={63.391}
          y2={124.03}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A90D6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience21_linear"
          x1={73.436}
          y1={87.372}
          x2={73.436}
          y2={89.36}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8DADE7" />
          <stop offset={1} stopColor="#88B1DA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience22_linear"
          x1={93.857}
          y1={92.656}
          x2={82.474}
          y2={92.656}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience23_linear"
          x1={109.014}
          y1={86.591}
          x2={61.574}
          y2={86.591}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience24_linear"
          x1={70.123}
          y1={20.137}
          x2={70.123}
          y2={55.702}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience25_linear"
          x1={75.065}
          y1={21.76}
          x2={75.065}
          y2={32.649}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience26_linear"
          x1={73.655}
          y1={22.942}
          x2={73.655}
          y2={30.051}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience27_linear"
          x1={78.908}
          y1={23.407}
          x2={78.908}
          y2={29.83}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#56548C" />
          <stop offset={0.179} stopColor="#444979" />
          <stop offset={0.504} stopColor="#28395D" />
          <stop offset={0.789} stopColor="#172F4C" />
          <stop offset={1} stopColor="#112B46" />
        </linearGradient>
        <linearGradient
          id="SvgExperience28_linear"
          x1={82.742}
          y1={44.414}
          x2={66.48}
          y2={78.457}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7EAFFF" />
          <stop offset={0.433} stopColor="#5384EE" />
          <stop offset={0.806} stopColor="#5159C0" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience29_linear"
          x1={101.639}
          y1={42.833}
          x2={94.887}
          y2={67.581}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={0.806} stopColor="#406DC9" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience30_linear"
          x1={75.183}
          y1={42.541}
          x2={64.676}
          y2={66.67}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7CACFE" />
          <stop offset={0.433} stopColor="#74A5FB" />
          <stop offset={1} stopColor="#626DF0" />
        </linearGradient>
        <linearGradient
          id="SvgExperience31_linear"
          x1={86.414}
          y1={55.857}
          x2={86.414}
          y2={69.198}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
        <linearGradient
          id="SvgExperience32_linear"
          x1={12.16}
          y1={71.52}
          x2={12.16}
          y2={125}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience33_linear"
          x1={26.799}
          y1={71.52}
          x2={26.799}
          y2={124.982}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience34_linear"
          x1={15.12}
          y1={71.539}
          x2={15.12}
          y2={80.112}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience35_linear"
          x1={138.886}
          y1={71.539}
          x2={138.886}
          y2={124.982}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience36_linear"
          x1={135.961}
          y1={71.539}
          x2={135.961}
          y2={79.109}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience37_linear"
          x1={155.76}
          y1={71.539}
          x2={155.76}
          y2={124.982}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience38_linear"
          x1={152.82}
          y1={71.539}
          x2={152.82}
          y2={79.109}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4F79C6" />
          <stop offset={1} stopColor="#AAD5FF" />
        </linearGradient>
        <linearGradient
          id="SvgExperience39_linear"
          x1={81.101}
          y1={68.693}
          x2={81.101}
          y2={74.564}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#96B8F5" />
          <stop offset={1} stopColor="#BCDBFA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgExperience;
